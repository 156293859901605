import React from 'react';
import './App.css';
import Placeholder from './Placeholder';

function App() {
  return (
    <div className="App">
			< Placeholder />
    </div>
  );
}

export default App;
